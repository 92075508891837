import React, { FC, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Store } from 'src//types';
import {
  cleanChosenUser,
  clearSearchUsers,
  createUser,
  deleteUser,
  editUser,
  getUser,
  getUsers,
  selectUser,
} from 'src/modules/users/actions';
import UsersTable from './components/UsersTable';
import { AddButton } from 'src/components/AddButton';
import { Modal } from 'src/components/Modal';
import UsersInnerModal from './components/UsersInnerModal';
import { useTranslation } from 'react-i18next';
import { ROLES } from 'src/constants/constants';
import Search from './components/Search';
import { useDebounce } from 'react-use';

const DELAY = 500;

type RoleType = keyof typeof ROLES;

const Users: FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { appLanguage } = useSelector((state: Store) => state.settings);
  const { loading, users, searchUsers } = useSelector((state: Store) => state.users);

  const [createUserModalVisible, setCreateUserModalVisible] = useState<boolean>(false);
  const [editUserModalVisible, setEditUserModalVisible] = useState<boolean>(false);
  const [searchValue, setSearchValue] = useState<string>('');

  const allUsers = searchUsers.length > 0 ? searchUsers : users;

  const handleChangeSearch = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(event.target.value);
  }, []);

  useDebounce(
    () => {
      const params = {
        username: searchValue.trim(),
      };
      if (searchValue.trim()) {
        dispatch(getUser.request(params));
      }
      if (!searchValue.trim()) {
        dispatch(clearSearchUsers());
      }
    },
    DELAY,
    [searchValue],
  );

  useEffect(() => {
    dispatch(getUsers.request());
  }, [dispatch]);

  const openCreateUserModal = useCallback(() => {
    dispatch(cleanChosenUser());
    setCreateUserModalVisible(true);
  }, [dispatch]);

  const hideCreateUserModal = useCallback(() => {
    setCreateUserModalVisible(false);
  }, []);

  const handleCreateUser = useCallback(
    (payload: {
      username: string;
      firstName: string;
      lastName: string;
      authorities: RoleType[];
    }) => {
      dispatch(
        createUser.request({
          requestBody: {
            ...payload,
            language: appLanguage,
          },
          onSuccessCb: () => {
            setCreateUserModalVisible(false);
          },
        }),
      );
    },
    [appLanguage, dispatch],
  );

  const handleDelete = (userId: number) => {
    dispatch(
      deleteUser.request({
        userId: userId,
      }),
    );
  };

  const handleOpenEditUserModal = useCallback(() => {
    setEditUserModalVisible(true);
  }, []);

  const handleSelectUser = useCallback(
    (username: string) => {
      const user = allUsers.find((item) => item.username === username);
      if (user) {
        dispatch(selectUser(user));
        handleOpenEditUserModal();
      }
    },
    [dispatch, allUsers, handleOpenEditUserModal],
  );

  const handleHideEditUserModal = useCallback(() => {
    setEditUserModalVisible(false);
    dispatch(cleanChosenUser());
  }, [dispatch]);

  const handleEditUser = useCallback(
    (payload: {
      username: string;
      firstName: string;
      lastName: string;
      authorities: RoleType[];
    }) => {
      dispatch(
        editUser.request({
          requestBody: {
            ...payload,
            language: appLanguage,
          },
          onSuccessCb: () => {
            setEditUserModalVisible(false);
            dispatch(clearSearchUsers());
            setSearchValue('');
          },
        }),
      );
    },
    [appLanguage, dispatch],
  );

  return (
    <div>
      <Search searchValue={searchValue} onChange={handleChangeSearch} />
      <UsersTable
        users={allUsers}
        loading={loading}
        onDelete={handleDelete}
        onEdit={handleSelectUser}
      />
      <AddButton onClick={openCreateUserModal} />
      <Modal
        title={t('users.createUser')}
        isOpen={createUserModalVisible}
        handleClose={hideCreateUserModal}>
        <UsersInnerModal onSubmit={handleCreateUser} onCloseModal={hideCreateUserModal} />
      </Modal>
      <Modal
        title={t('users.editUser')}
        isOpen={editUserModalVisible}
        handleClose={handleHideEditUserModal}>
        <UsersInnerModal isEdit onSubmit={handleEditUser} onCloseModal={handleHideEditUserModal} />
      </Modal>
    </div>
  );
};

export default Users;
