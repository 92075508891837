import React from 'react';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

import { changeLanguage as changeLanguageAction } from 'src/modules/settings/actions';

import styles from './language.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import { Store } from 'src/types';
import i18n from '../../../../config/i18n/i18n';
import { LANGUAGES } from '../../../../constants/constants';

export const ChangeLanguage: React.FC = () => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const { appLanguage } = useSelector((state: Store) => state.settings);
  const dispatch = useDispatch();

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleChangeLanguage = (lang: LANGUAGES.EN | LANGUAGES.DE) => {
    dispatch(changeLanguageAction({ language: lang }));
    localStorage.setItem('lang', lang);
    i18n.changeLanguage(lang.toLowerCase());
    handleClose();
  };

  return (
    <div>
      <Button
        id="basic-button"
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}>
        <img
          className={styles.flag}
          src={
            appLanguage === LANGUAGES.DE
              ? require('src/assets/icons/german.png')
              : require('src/assets/icons/united-kingdom.png')
          }
        />
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        sx={{ mt: '0px', pr: '12px' }}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}>
        <MenuItem
          sx={{ pr: '30px', pl: '30px' }}
          selected={appLanguage === LANGUAGES.EN}
          onClick={() => handleChangeLanguage(LANGUAGES.EN)}>
          EN
        </MenuItem>
        <MenuItem
          sx={{ pr: '30px', pl: '30px' }}
          selected={appLanguage === LANGUAGES.DE}
          onClick={() => handleChangeLanguage(LANGUAGES.DE)}>
          DE
        </MenuItem>
      </Menu>
    </div>
  );
};
