import React, { FC } from 'react';
import { Theme, useTheme } from '@mui/material/styles';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import OutlinedInput from '@mui/material/OutlinedInput';
import MenuItem from '@mui/material/MenuItem';
import { SelectChangeEvent } from '@mui/material/Select';
import { ROLES } from 'src/constants/constants';
import { useTranslation } from 'react-i18next';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

type RoleType = keyof typeof ROLES;

const names: { id: number; value: RoleType }[] = [
  { id: 1, value: 'ROLE_ADMIN' },
  { id: 2, value: 'ROLE_SUPERADMIN' },
];
function getStyles(name: string, personName: string[], theme: Theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

interface MyMultiSelectProps {
  authorities: RoleType[];
  onChange: (event: SelectChangeEvent<('ROLE_SUPERADMIN' | 'ROLE_ADMIN')[]>) => void;
}

const MyMultiSelect: FC<MyMultiSelectProps> = ({ authorities, onChange }) => {
  const { t } = useTranslation();
  const theme = useTheme();

  return (
    <>
      <InputLabel id="demo-multiple-name-label">{t('users.role')}</InputLabel>
      <Select
        labelId="demo-multiple-name-label"
        id="demo-multiple-name"
        multiple
        value={authorities}
        onChange={onChange}
        input={<OutlinedInput label={t('users.role')} />}
        MenuProps={MenuProps}>
        {names.map((item) => (
          <MenuItem
            key={item.value}
            value={item.value}
            style={getStyles(item.value, authorities, theme)}>
            {ROLES[item.value]}
          </MenuItem>
        ))}
      </Select>
    </>
  );
};

export default MyMultiSelect;
