/* eslint-disable @typescript-eslint/no-explicit-any */
import { ActionType } from 'deox';
import { takeLatest, put } from 'redux-saga/effects';
import { API } from 'src/api';

import { getYoutubeVideos, editYoutubeVideos } from './actions';

import { showStackBar } from 'src/modules/settings/actions';

function* getYoutubeVideosSaga({ payload }: ActionType<typeof getYoutubeVideos.request>) {
  try {
    const { data } = yield API.getYoutubeVideos(payload);

    yield put(getYoutubeVideos.success(data));
  } catch (e: any) {
    yield put(getYoutubeVideos.fail(e?.message || e.data?.detail));
  }
}

function* editYoutubeVideosSaga({ payload }: ActionType<typeof editYoutubeVideos.request>) {
  try {
    const { data } = yield API.editYoutubeVideos(payload);

    yield put(editYoutubeVideos.success(data));

    yield put(
      showStackBar({
        visible: true,
        message: 'Changes has been saved.',
        variant: 'success',
      }),
    );
  } catch (e: any) {
    yield put(editYoutubeVideos.fail(e?.message || e.data?.detail));
  }
}

export function* watchYoutube() {
  yield takeLatest(getYoutubeVideos.request, getYoutubeVideosSaga);
  yield takeLatest(editYoutubeVideos.request, editYoutubeVideosSaga);
}
