import { createReducer } from 'deox';
import { produce } from 'immer';

import { NotificationsState } from './types';

import { getNotifications, createNotification, selectNotify, cleanChosenNotify } from './actions';

const initialState: NotificationsState = {
  notifications: [],
  loading: false,
  error: '',
  chosenNotify: null,
  currentPage: 0,
  totalPages: 0,
};

export const notifyReducer = createReducer(initialState, (handleAction) => [
  handleAction(getNotifications.request, (state) =>
    produce(state, (draft) => {
      draft.loading = true;
    }),
  ),
  handleAction(getNotifications.success, (state, { payload }) =>
    produce(state, (draft) => {
      draft.loading = false;
      draft.totalPages = payload.totalPages;
      draft.notifications = payload.content;
    }),
  ),
  handleAction(getNotifications.fail, (state) =>
    produce(state, (draft) => {
      draft.loading = false;
    }),
  ),
  handleAction(createNotification.request, (state) =>
    produce(state, (draft) => {
      draft.loading = true;
    }),
  ),
  handleAction(createNotification.success, (state) =>
    produce(state, (draft) => {
      draft.loading = false;
    }),
  ),
  handleAction(createNotification.fail, (state) =>
    produce(state, (draft) => {
      draft.loading = false;
    }),
  ),
  handleAction(selectNotify, (state, { payload }) =>
    produce(state, (draft) => {
      draft.chosenNotify = payload;
    }),
  ),
  handleAction(cleanChosenNotify, (state) =>
    produce(state, (draft) => {
      draft.chosenNotify = null;
    }),
  ),
]);
