import { createAction } from 'deox';

import {
  GetYoutubeVideosRequest,
  GetYoutubeVideosResponse,
  EditYoutubeVideosRequestBody,
  EditYoutubeVideosResponse,
} from 'src/types';

export const getYoutubeVideos = {
  request: createAction(
    'youtube/GET_YOTUBE_VIDEOS_REQUEST',
    (resolve) => (payload: GetYoutubeVideosRequest) => resolve(payload),
  ),
  success: createAction(
    'youtube/GET_YOTUBE_VIDEOS_SUCCESS',
    (resolve) => (payload: GetYoutubeVideosResponse) => resolve(payload),
  ),
  fail: createAction(
    'youtube/GET_YOTUBE_VIDEOS_FAIL',
    (resolve) => (payload: { error: string }) => resolve(payload),
  ),
};

export const editYoutubeVideos = {
  request: createAction(
    'youtube/EDIT_YOTUBE_VIDEOS_REQUEST',
    (resolve) => (payload: EditYoutubeVideosRequestBody) => resolve(payload),
  ),
  success: createAction(
    'youtube/EDIT_YOTUBE_VIDEOS_SUCCESS',
    (resolve) => (payload: EditYoutubeVideosResponse) => resolve(payload),
  ),
  fail: createAction(
    'youtube/EDIT_YOTUBE_VIDEOS_FAIL',
    (resolve) => (payload: { error: string }) => resolve(payload),
  ),
};
