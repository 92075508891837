/* eslint-disable @typescript-eslint/no-explicit-any */
import { ActionType } from 'deox';
import { takeLatest, put, select } from 'redux-saga/effects';
import { API } from 'src/api';
import { Store } from 'src/types';

import { createNews, getNews, getNewsById, editNewsById, deleteNewsById } from './actions';

import { showStackBar } from 'src/modules/settings/actions';

function* createNewsSaga({ payload }: ActionType<typeof createNews.request>) {
  const { body, onSuccessCb } = payload;
  const { appLanguage } = yield select((state: Store) => state.settings);

  try {
    const { data } = yield API.createNews(body);

    yield put(createNews.success(data));

    if (onSuccessCb) {
      onSuccessCb();
    }

    yield put(
      getNews.request({
        language: appLanguage,
        page: 0,
        size: 8,
        sort: 'modifiedAt,desc',
        section: payload.body.section,
      }),
    );

    yield put(
      showStackBar({
        visible: true,
        message: 'News has been created.',
        variant: 'success',
      }),
    );
  } catch (e: any) {
    yield put(createNews.fail(e.message));
  }
}

function* getNewsSaga({ payload }: ActionType<typeof getNews.request>) {
  try {
    const { data } = yield API.getNews(payload);

    yield put(getNews.success(data));
  } catch (e: any) {
    yield put(getNews.fail(e?.message || e.data?.detail));
  }
}

function* getNewsByIdSaga({ payload }: ActionType<typeof getNewsById.request>) {
  try {
    const { data } = yield API.getNewsById({ newsId: payload.newsId });

    yield put(getNewsById.success(data));
  } catch (e: any) {
    yield put(getNewsById.fail({ error: e.message }));
  }
}

function* editNewsByIdSaga({ payload }: ActionType<typeof editNewsById.request>) {
  try {
    const { body, onSuccessCb } = payload;

    const { choosenNewsId } = yield select((state: Store) => state.news);
    const { appLanguage } = yield select((state: Store) => state.settings);

    const { data } = yield API.editNewsById({ body, newsId: choosenNewsId });

    yield put(editNewsById.success(data));

    yield put(
      getNews.request({
        language: appLanguage,
        page: 0,
        size: 8,
        sort: 'modifiedAt,desc',
        section: payload.body.section,
      }),
    );

    if (onSuccessCb) {
      onSuccessCb();
    }

    yield put(
      showStackBar({
        visible: true,
        message: 'News has been changed.',
        variant: 'success',
      }),
    );
  } catch (e: any) {
    yield put(editNewsById.fail({ error: e.message }));
  }
}

function* deleteNewsByIdSaga({ payload }: ActionType<typeof deleteNewsById.request>) {
  try {
    const { appLanguage } = yield select((state: Store) => state.settings);

    yield API.deleteNewsById({ newsId: payload.newsId });

    yield put(deleteNewsById.success());

    yield put(
      getNews.request({
        language: appLanguage,
        page: 0,
        size: 8,
        sort: 'modifiedAt,desc',
        section: payload.section,
      }),
    );

    yield put(
      showStackBar({
        visible: true,
        message: 'News has been deleted.',
        variant: 'error',
      }),
    );
  } catch (e: any) {
    yield put(deleteNewsById.fail({ error: e.message }));
  }
}

export function* watchNews() {
  yield takeLatest(createNews.request, createNewsSaga);
  yield takeLatest(getNews.request, getNewsSaga);
  yield takeLatest(getNewsById.request, getNewsByIdSaga);
  yield takeLatest(editNewsById.request, editNewsByIdSaga);
  yield takeLatest(deleteNewsById.request, deleteNewsByIdSaga);
}
