import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  cleanChoosenNews,
  createNews,
  deleteNewsById,
  editNewsById,
  getNews,
  getNewsById,
} from 'src/modules/news/actions';

import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';

import { Loading } from 'src/components/Loading';
import { Modal } from 'src/components/Modal';
import { AddButton } from 'src/components/AddButton';

import { ModalInner } from './components/ModalInner';

import { EditNewsRequestBody, Store } from 'src/types';
import { Grid, Typography } from '@mui/material';
import { IntroItem } from './components/IntroItem';
import { useTranslation } from 'react-i18next';

export const PatriciaIntroduction: React.FC = () => {
  const { t } = useTranslation();
  const [modalOpen, setModalOpen] = useState(false);
  const [page, setPage] = useState(0);
  const [createAboutModalVisible, setCreateAboutModalVisible] = useState(false);

  const { loading, news, totalPages } = useSelector((state: Store) => state.news);
  const { appLanguage } = useSelector((state: Store) => state.settings);

  const dispatch = useDispatch();

  const openCreateNewsModal = useCallback(() => {
    setCreateAboutModalVisible(true);
  }, []);

  const hideCreateNewsModal = useCallback(() => {
    setCreateAboutModalVisible(false);
  }, []);

  const handleSelectAboutId = useCallback(
    (newsId: number) => {
      dispatch(getNewsById.request({ newsId: newsId }));
      setModalOpen(true);
    },
    [dispatch],
  );

  const onDeleteNews = useCallback(
    (newsId: number) => {
      dispatch(deleteNewsById.request({ newsId, section: 'SC_INTRO' }));
    },
    [dispatch],
  );

  const onCloseModal = useCallback(() => {
    setModalOpen(false);

    dispatch(cleanChoosenNews());
  }, [dispatch]);

  const onEditChosenNews = useCallback(
    (payload: EditNewsRequestBody) => {
      dispatch(
        editNewsById.request({
          body: payload,
          onSuccessCb: () => {
            onCloseModal();
          },
        }),
      );
    },
    [dispatch, onCloseModal],
  );

  const onCreateNewAboutBlock = useCallback(
    (payload: { body: string; imageUrl: string; title: string }) => {
      const { body, imageUrl, title } = payload;

      dispatch(
        createNews.request({
          body: {
            body,
            imageUrl,
            title,
            language: appLanguage,
            section: 'SC_INTRO',
          },
          onSuccessCb: () => {
            hideCreateNewsModal();
          },
        }),
      );
    },
    [appLanguage, dispatch, hideCreateNewsModal],
  );

  const handleChangePage = (event: React.ChangeEvent<unknown>, value: number) => {
    // need subtract 1, because pages received from back is starting from 0
    setPage(value - 1);
  };

  useEffect(() => {
    dispatch(
      getNews.request({
        language: appLanguage,
        page: page,
        size: 8,
        sort: 'modifiedAt,desc',
        section: 'SC_INTRO',
      }),
    );
  }, [dispatch, appLanguage, page]);

  if (loading) {
    return <Loading loading={loading} />;
  }

  return (
    <Grid container spacing={2} flexDirection={'column'}>
      <Grid item xs={12}>
        {news.length > 0 ? (
          news.map((item, index) => (
            <IntroItem
              inMobileApp={index === 0 || index === 1 || index === 2 || index === 3}
              key={item.id}
              body={item.body}
              imageUrl={item.imageUrl}
              title={item.title}
              id={item.id}
              onClick={handleSelectAboutId}
              onDelete={onDeleteNews}
              modifiedDate={item.modifiedDate}
              modifiedByFirstName={item.modifiedByFirstName}
              modifiedByLastName={item.modifiedByLastName}
            />
          ))
        ) : (
          <Typography sx={{ fontSize: '14px' }} component="h3" variant="h5" color="inherit">
            {t('patriciaIntroduction.seemsLike')}
          </Typography>
        )}
        {news.length > 0 && (
          <Stack direction="row" justifyContent="center" spacing={2}>
            <Pagination onChange={handleChangePage} count={totalPages} page={page + 1} />
          </Stack>
        )}

        <Modal
          title={t('patriciaIntroduction.editPatricia')}
          isOpen={modalOpen}
          handleClose={onCloseModal}>
          <ModalInner onCloseModal={onCloseModal} onSubmit={onEditChosenNews} />
        </Modal>
        <Modal
          title={t('patriciaIntroduction.createPatricia')}
          isOpen={createAboutModalVisible}
          handleClose={hideCreateNewsModal}>
          <ModalInner onCloseModal={hideCreateNewsModal} onSubmit={onCreateNewAboutBlock} />
        </Modal>
        <AddButton onClick={openCreateNewsModal} />
      </Grid>
    </Grid>
  );
};
