import React, { FC } from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  width: 400,
  transform: 'translate(-50%, -50%)',
  bgcolor: '#ffffff',
  boxShadow: 24,
  p: 3,
};

type Props = {
  open: boolean;
  handleClose: () => void;
};

export const EmailConfirmationModal: FC<Props> = ({ open, handleClose }) => {
  const history = useHistory();
  const { t } = useTranslation();

  const handleCloseClick = () => {
    history.goBack();
  };

  return (
    <div>
      <Modal open={open} onClose={handleClose} closeAfterTransition>
        <Fade in={open}>
          <Box sx={style}>
            <Typography id="transition-modal-title" variant="h6" component="h2">
              {t('resetPassword.checkEmail')}
            </Typography>
            <Typography id="transition-modal-description" sx={{ mt: 2 }}>
              {t('resetPassword.checkEmailInstruction')}
            </Typography>

            <Button
              onClick={handleCloseClick}
              style={{ marginTop: '24px', display: 'block', width: '100%' }}
              variant="contained">
              {t('resetPassword.close')}
            </Button>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
};
