import React, { memo } from 'react';
import { Redirect, Route } from 'react-router-dom';

type Props = {
  isAuthenticated: boolean;
  path: string;
  children: React.ReactElement;
};

export const PublicRoute: React.FC<Props> = memo(function PublicRoute({
  children,
  isAuthenticated,
  ...restProps
}) {
  return (
    <Route
      {...restProps}
      render={() => (!isAuthenticated ? children : <Redirect to="/dashboard" />)}
    />
  );
});
