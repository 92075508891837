import { UsersState } from './types';
import { createReducer } from 'deox';
import {
  createUser,
  getUsers,
  getUser,
  selectUser,
  cleanChosenUser,
  clearSearchUsers,
  clearError,
} from './actions';
import { produce } from 'immer';

const initialState: UsersState = {
  users: [],
  searchUsers: [],
  loading: false,
  error: '',
  chosenUser: null,
};

export const usersReducer = createReducer(initialState, (handleAction) => [
  handleAction(getUsers.request, (state) =>
    produce(state, (draft) => {
      draft.loading = true;
    }),
  ),
  handleAction(getUsers.success, (state, { payload }) =>
    produce(state, (draft) => {
      draft.users = payload.content;
      draft.loading = false;
    }),
  ),
  handleAction(getUsers.fail, (state) =>
    produce(state, (draft) => {
      draft.loading = false;
    }),
  ),
  handleAction(createUser.request, (state) =>
    produce(state, (draft) => {
      draft.loading = true;
    }),
  ),
  handleAction(createUser.success, (state) =>
    produce(state, (draft) => {
      draft.loading = false;
    }),
  ),
  handleAction(createUser.fail, (state, payload) =>
    produce(state, (draft) => {
      draft.loading = false;
      draft.error = payload.payload.error;
    }),
  ),
  handleAction(selectUser, (state, { payload }) =>
    produce(state, (draft) => {
      draft.chosenUser = payload;
    }),
  ),
  handleAction(cleanChosenUser, (state) =>
    produce(state, (draft) => {
      draft.chosenUser = null;
    }),
  ),
  handleAction(getUser.request, (state) =>
    produce(state, (draft) => {
      draft.loading = true;
    }),
  ),
  handleAction(getUser.success, (state, { payload }) =>
    produce(state, (draft) => {
      draft.searchUsers = payload;
      draft.loading = false;
    }),
  ),
  handleAction(getUser.fail, (state) =>
    produce(state, (draft) => {
      draft.loading = false;
    }),
  ),
  handleAction(clearSearchUsers, (state) =>
    produce(state, (draft) => {
      draft.searchUsers = [];
    }),
  ),
  handleAction(clearError, (state) =>
    produce(state, (draft) => {
      draft.error = '';
    }),
  ),
]);
