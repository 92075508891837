import React, { memo } from 'react';
import AddIcon from '@mui/icons-material/Add';
import ButtonBase from '@mui/material/ButtonBase';

type Props = {
  onClick: () => void;
};

export const AddButton: React.FC<Props> = memo(function AddButton({ onClick }) {
  return (
    <ButtonBase
      onClick={onClick}
      sx={{
        position: 'absolute',
        borderRadius: 100,
        bottom: 20,
        right: 25,
        width: '50px',
        height: '50px',
        backgroundColor: '#1976D2',
      }}>
      <AddIcon sx={{ color: 'white' }} />
    </ButtonBase>
  );
});
