import { createAction } from 'deox';

import {
  CreateNotificationRequestBody,
  CreateNotificationResponse,
  DeleteNotificationRequestParams,
  DeleteNotificationResponse,
  EditNotificationRequestBody,
  EditNotificationResponse,
  GetNotificationsRequestParams,
  GetNotificationsResponse,
  Notification,
} from 'src/types';

export const getNotifications = {
  request: createAction(
    'notify/GET_NOTIFICATIONS_REQUEST',
    (resolve) => (payload: GetNotificationsRequestParams) => resolve(payload),
  ),
  success: createAction(
    'notify/GET_NOTIFICATIONS_SUCCESS',
    (resolve) => (payload: GetNotificationsResponse) => resolve(payload),
  ),
  fail: createAction(
    'notify/GET_NOTIFICATIONS_FAIL',
    (resolve) => (payload: { error: string }) => resolve(payload),
  ),
};

export const createNotification = {
  request: createAction(
    'notify/CREATE_NOTIFICATION_REQUEST',
    (resolve) =>
      (payload: { requestBody: CreateNotificationRequestBody; onSuccessCb?: () => void }) =>
        resolve(payload),
  ),
  success: createAction(
    'notify/CREATE_NOTIFICATION_SUCCESS',
    (resolve) => (payload: CreateNotificationResponse) => resolve(payload),
  ),
  fail: createAction(
    'notify/CREATE_NOTIFICATION_FAIL',
    (resolve) => (payload: { error: string }) => resolve(payload),
  ),
};

export const editNotification = {
  request: createAction(
    'notify/EDIT_NOTIFICATION_REQUEST',
    (resolve) =>
      (payload: { requestBody: EditNotificationRequestBody; onSuccessCb?: () => void }) =>
        resolve(payload),
  ),
  success: createAction(
    'notify/EDIT_NOTIFICATION_SUCCESS',
    (resolve) => (payload: EditNotificationResponse) => resolve(payload),
  ),
  fail: createAction(
    'notify/EDIT_NOTIFICATION_FAIL',
    (resolve) => (payload: { error: string }) => resolve(payload),
  ),
};

export const deleteNotification = {
  request: createAction(
    'notify/DELETE_NOTIFICATION_REQUEST',
    (resolve) => (payload: DeleteNotificationRequestParams) => resolve(payload),
  ),
  success: createAction(
    'notify/DELETE_NOTIFICATION_SUCCESS',
    (resolve) => (payload: DeleteNotificationResponse) => resolve(payload),
  ),
  fail: createAction(
    'notify/DELETE_NOTIFICATION_FAIL',
    (resolve) => (payload: { error: string }) => resolve(payload),
  ),
};

export const selectNotify = createAction(
  'notify/SELECT_NOTIFY',
  (resolve) => (payload: Notification | null) => resolve(payload),
);

export const cleanChosenNotify = createAction('notify/CLEAN');
