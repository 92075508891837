import React, { FC, useEffect, useState } from 'react';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import { useTranslation } from 'react-i18next';
import { Box } from '@mui/material';
import TextField from '@mui/material/TextField';

import MyMultiSelect from 'src/pages/Users/components/MyMultiSelect/MyMultiSelect';
import { ROLES } from 'src/constants/constants';
import { SelectChangeEvent } from '@mui/material/Select';
import { Loading } from 'src/components/Loading';
import { useDispatch, useSelector } from 'react-redux';
import { Store } from 'src/types';
import { ErrorMessage } from 'src/components/ErrorMessage';
import { validateEmail } from 'src/helpers/isValidImageUrl';
import { clearError } from '../../../../modules/users/actions';

type RoleType = keyof typeof ROLES;

interface UserInnerModalProps {
  isEdit?: boolean;
  onSubmit: (payload: {
    username: string;
    firstName: string;
    lastName: string;
    authorities: RoleType[];
  }) => void;

  onCloseModal: () => void;
}

const UsersInnerModal: FC<UserInnerModalProps> = ({ isEdit, onSubmit, onCloseModal }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { loading, chosenUser, error } = useSelector((state: Store) => state.users);

  const [username, setUsername] = useState<string>('');
  const [firstName, setFirstName] = useState<string>('');
  const [lastName, setLastName] = useState<string>('');
  const [authorities, setAuthorities] = React.useState<RoleType[]>([]);

  const [isValidUsername, setIsValidUsername] = useState<string>('');

  const handleChangeUsername = (event: React.ChangeEvent<HTMLInputElement>) => {
    setUsername(event.target.value);
  };
  const handleChangeFirstName = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFirstName(event.target.value);
  };
  const handleChangeLastName = (event: React.ChangeEvent<HTMLInputElement>) => {
    setLastName(event.target.value);
  };

  const handleChange = (event: SelectChangeEvent<typeof authorities>) => {
    const {
      target: { value },
    } = event;

    const selectedValues = typeof value === 'string' ? value.split(',') : value;

    setAuthorities(selectedValues as RoleType[]);
  };

  const validateFields = () => {
    if (!validateEmail(username)) {
      setIsValidUsername(t('users.enterCorrectEmail'));
      return false;
    }
    return true;
  };

  const handleSubmit = () => {
    const isFieldsValid = validateFields();
    if (isFieldsValid) {
      onSubmit({
        username,
        firstName,
        lastName,
        authorities,
      });
    }
  };

  useEffect(() => {
    if (chosenUser) {
      setUsername(chosenUser.username);
      setFirstName(chosenUser.firstName);
      setLastName(chosenUser.lastName);
      setAuthorities(chosenUser.authorities);
    }
  }, [chosenUser]);

  useEffect(() => {
    return () => {
      dispatch(clearError());
    };
  }, [dispatch]);

  const getAllFieldsFulfilled = () =>
    Boolean(username && firstName && lastName && authorities.length > 0);

  const allFieldsFulfilled = getAllFieldsFulfilled();

  if (loading) {
    return <Loading loading={loading} />;
  }

  return (
    <Box
      component="form"
      sx={{
        '& .MuiTextField-root': { mb: 1, mt: 1 },
        display: 'flex',
        flexDirection: 'column',
      }}
      noValidate
      autoComplete="off">
      <TextField
        value={username}
        fullWidth
        id="standard-multiline-flexible"
        label={t('users.email')}
        onChange={handleChangeUsername}
        multiline
        rows={2}
        variant="standard"
        placeholder={t('users.enterEmail')}
        inputProps={{ maxLength: 150 }}
        disabled={isEdit}
      />
      {isValidUsername && <ErrorMessage errorMessage={isValidUsername} />}
      {error === 'emailexists' && <ErrorMessage errorMessage={t('users.emailexists')} />}
      <TextField
        value={firstName}
        fullWidth
        id="standard-multiline-flexible"
        label={t('users.firstName')}
        onChange={handleChangeFirstName}
        multiline
        rows={2}
        variant="standard"
        placeholder={t('users.enterFirstName')}
        inputProps={{ maxLength: 150 }}
      />
      <TextField
        value={lastName}
        fullWidth
        id="standard-multiline-flexible"
        label={t('users.lastName')}
        onChange={handleChangeLastName}
        multiline
        rows={2}
        variant="standard"
        placeholder={t('users.enterLastName')}
        inputProps={{ maxLength: 150 }}
      />
      <MyMultiSelect authorities={authorities} onChange={handleChange} />
      <DialogActions>
        <Button onClick={onCloseModal}>{t('general.cancel')}</Button>
        <Button onClick={handleSubmit} disabled={!allFieldsFulfilled}>
          {t('general.save')}
        </Button>
      </DialogActions>
    </Box>
  );
};

export default UsersInnerModal;
