/* eslint-disable @typescript-eslint/no-explicit-any */
import { ActionType } from 'deox';
import { takeLatest, put, select } from 'redux-saga/effects';
import { API } from 'src/api';

import { showStackBar } from 'src/modules/settings/actions';
import {
  createNotification,
  getNotifications,
  editNotification,
  deleteNotification,
} from './actions';

import { Store } from 'src/types';

function* createNotificationSaga({ payload }: ActionType<typeof createNotification.request>) {
  const { onSuccessCb, requestBody } = payload;

  const { appLanguage } = yield select((state: Store) => state.settings);

  try {
    yield API.createNotification(requestBody);

    yield put(createNotification.success(''));

    onSuccessCb && onSuccessCb();

    yield put(
      showStackBar({
        visible: true,
        message: 'Notification has been created.',
        variant: 'success',
      }),
    );

    yield put(
      getNotifications.request({
        language: appLanguage,
        page: 0,
        size: 8,
        sort: 'publishTime,desc',
      }),
    );
  } catch (e: any) {
    yield put(createNotification.fail({ error: e.message }));
  }
}

function* getNotificationsSaga({ payload }: ActionType<typeof getNotifications.request>) {
  try {
    const { data } = yield API.getNotifications(payload);

    yield put(getNotifications.success(data));
  } catch (e: any) {
    yield put(getNotifications.fail({ error: e.message }));
  }
}

function* editNotificationSaga({ payload }: ActionType<typeof editNotification.request>) {
  const { requestBody, onSuccessCb } = payload;

  const { id } = yield select((state: Store) => state.notify.chosenNotify);
  const { appLanguage } = yield select((state: Store) => state.settings);

  try {
    const { data } = yield API.editNotification(requestBody, id);

    yield put(editNotification.success(data));

    onSuccessCb && onSuccessCb();

    yield put(
      showStackBar({
        visible: true,
        message: 'Notification has been changed.',
        variant: 'success',
      }),
    );

    yield put(
      getNotifications.request({
        language: appLanguage,
        page: 0,
        size: 8,
        sort: 'publishTime,desc',
      }),
    );
  } catch (e: any) {
    yield put(editNotification.fail({ error: e.message }));
  }
}

function* deleteNotificationsSaga({ payload }: ActionType<typeof deleteNotification.request>) {
  const { appLanguage } = yield select((state: Store) => state.settings);

  try {
    yield API.deleteNotification(payload);

    yield put(deleteNotification.success(''));

    yield put(
      showStackBar({
        visible: true,
        message: 'Notification has been deleted.',
        variant: 'error',
      }),
    );

    yield put(
      getNotifications.request({
        language: appLanguage,
        page: 0,
        size: 8,
        sort: 'publishTime,desc',
      }),
    );
  } catch (e: any) {
    yield put(deleteNotification.fail({ error: e.message }));
  }
}

export function* watchNotify() {
  yield takeLatest(createNotification.request, createNotificationSaga);
  yield takeLatest(getNotifications.request, getNotificationsSaga);
  yield takeLatest(editNotification.request, editNotificationSaga);
  yield takeLatest(deleteNotification.request, deleteNotificationsSaga);
}
