import React, { useState, useCallback, useMemo, useEffect } from 'react';
import { Grid, Box } from '@mui/material';

import { CourseCard } from './components/CourseCard';
import { Loading } from 'src/components/Loading';
import { LoadingButton } from '@mui/lab';

import { checkImageUrl } from 'src/helpers/isValidImageUrl';
import { ErrorMessage } from 'src/components/ErrorMessage';

import { getCourses, editCourses } from 'src/modules/courses/actions';

import { Course, Store } from 'src/types';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import HistoryChange from '../../components/HistoryChange/HistoryChange';

export const Courses: React.FC = () => {
  const { t } = useTranslation();
  const [error, setError] = useState('');

  const { appLanguage } = useSelector((state: Store) => state.settings);
  const { loading, editLoading, courses, modifiedDate, modifiedByFirstName, modifiedByLastName } =
    useSelector((state: Store) => state.courses);

  const [firstCourse, setFirstCourse] = useState<Course>({
    imageUrl: '',
    shopLink: '',
    id: '',
  });

  const [secondCourse, setSecondCourse] = useState<Course>({
    imageUrl: '',
    shopLink: '',
    id: '',
  });

  const [thirdCourse, setThirdCourse] = useState<Course>({
    imageUrl: '',
    shopLink: '',
    id: '',
  });

  const [fourthCourse, setFourthCourse] = useState<Course>({
    imageUrl: '',
    shopLink: '',
    id: '',
  });

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getCourses.request({ language: appLanguage }));
  }, [appLanguage, dispatch]);

  useEffect(() => {
    if (courses.length > 0) {
      courses.forEach((course, index) => {
        switch (index) {
          case 0:
            setFirstCourse(course);
            break;
          case 1:
            setSecondCourse(course);
            break;
          case 2:
            setThirdCourse(course);
            break;
          case 3:
            setFourthCourse(course);
            break;
        }
      });
    }
  }, [courses]);

  const validateStatus = useMemo(() => {
    const unfilledFields = [firstCourse, secondCourse, thirdCourse, fourthCourse].find((course) => {
      if (course.imageUrl === '' || course.shopLink === '' || course.id === '') {
        return true;
      }
    });

    return !!unfilledFields;
  }, [firstCourse, fourthCourse, secondCourse, thirdCourse]);

  const checkFieldsByValidImageUrl = useCallback(() => {
    const hasFieldsWithInvalidUrl = [firstCourse, secondCourse, thirdCourse, fourthCourse].find(
      (course) => {
        if (!checkImageUrl(course.imageUrl)) {
          return true;
        }

        return false;
      },
    );

    return !hasFieldsWithInvalidUrl;
  }, [firstCourse, fourthCourse, secondCourse, thirdCourse]);

  const onSubmit = useCallback(() => {
    const isAllFieldsValid = checkFieldsByValidImageUrl();

    if (!isAllFieldsValid) {
      setError(t('course.someFieldsIsInvalid'));

      return;
    }

    dispatch(
      editCourses.request({
        language: appLanguage,
        courses: [firstCourse, secondCourse, thirdCourse, fourthCourse],
      }),
    );
  }, [
    appLanguage,
    checkFieldsByValidImageUrl,
    dispatch,
    firstCourse,
    fourthCourse,
    secondCourse,
    thirdCourse,
    t,
  ]);

  const clearError = useCallback(() => {
    setError('');
  }, []);

  if (loading) {
    return <Loading loading={loading} />;
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Grid container flexDirection="column">
          <CourseCard
            onChange={setFirstCourse}
            fieldsValue={firstCourse}
            checkFieldsByValidImageUrl={checkFieldsByValidImageUrl}
            clearError={clearError}
          />
          <CourseCard
            onChange={setSecondCourse}
            fieldsValue={secondCourse}
            checkFieldsByValidImageUrl={checkFieldsByValidImageUrl}
            clearError={clearError}
          />
          <CourseCard
            onChange={setThirdCourse}
            fieldsValue={thirdCourse}
            checkFieldsByValidImageUrl={checkFieldsByValidImageUrl}
            clearError={clearError}
          />
          <CourseCard
            onChange={setFourthCourse}
            fieldsValue={fourthCourse}
            checkFieldsByValidImageUrl={checkFieldsByValidImageUrl}
            clearError={clearError}
          />
          {error && <ErrorMessage errorMessage={error} />}
        </Grid>
        <Box sx={{ display: 'flex', mt: 1 }} justifyContent="space-between">
          <HistoryChange
            modifiedByFirstName={modifiedByFirstName}
            modifiedByLastName={modifiedByLastName}
            modifiedDate={modifiedDate}
          />
          <LoadingButton
            onClick={onSubmit}
            disabled={validateStatus}
            loading={editLoading}
            variant="contained">
            {t('general.save')}
          </LoadingButton>
        </Box>
      </Grid>
    </Grid>
  );
};
