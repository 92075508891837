import { createAction } from 'deox';
import {
  CreateUserRequest,
  DeleteUserRequestParams,
  EditNotificationResponse,
  GetUsersResponse,
  User,
  GetUserRequestParams,
} from 'src/types';

export const getUsers = {
  request: createAction('users/GET_USERS_REQUEST', (resolve) => () => resolve()),
  success: createAction(
    'users/GET_USERS_SUCCESS',
    (resolve) => (payload: GetUsersResponse) => resolve(payload),
  ),
  fail: createAction(
    'users/GET_USERS_FAIL',
    (resolve) => (payload: { error: string }) => resolve(payload),
  ),
};

export const createUser = {
  request: createAction(
    'users/CREATE_USER_REQUEST',
    (resolve) => (payload: { requestBody: CreateUserRequest; onSuccessCb?: () => void }) =>
      resolve(payload),
  ),
  success: createAction('users/CREATE_USER_SUCCESS', (resolve) => () => resolve()),
  fail: createAction(
    'users/CREATE_USER_FAIL',
    (resolve) => (payload: { error: string }) => resolve(payload),
  ),
};

export const deleteUser = {
  request: createAction(
    'users/DELETE_USER_REQUEST',
    (resolve) => (payload: DeleteUserRequestParams) => resolve(payload),
  ),
  success: createAction('users/DELETE_USER_SUCCESS', (resolve) => () => resolve()),
  fail: createAction(
    'users/DELETE_USER_FAIL',
    (resolve) => (payload: { error: string }) => resolve(payload),
  ),
};

export const editUser = {
  request: createAction(
    'users/EDIT_USER_REQUEST',
    (resolve) => (payload: { requestBody: CreateUserRequest; onSuccessCb?: () => void }) =>
      resolve(payload),
  ),
  success: createAction(
    'users/EDIT_USER_SUCCESS',
    (resolve) => (payload: EditNotificationResponse) => resolve(payload),
  ),
  fail: createAction(
    'users/EDIT_USER_FAIL',
    (resolve) => (payload: { error: string }) => resolve(payload),
  ),
};

export const selectUser = createAction(
  'users/SELECT_USER',
  (resolve) => (payload: User | null) => resolve(payload),
);

export const cleanChosenUser = createAction('users/CLEAN');

export const getUser = {
  request: createAction(
    'users/GET_USER_REQUEST',
    (resolve) => (payload: GetUserRequestParams) => resolve(payload),
  ),
  success: createAction(
    'users/GET_USER_SUCCESS',
    (resolve) => (payload: User[]) => resolve(payload),
  ),
  fail: createAction(
    'users/GET_USER_FAIL',
    (resolve) => (payload: { error: string }) => resolve(payload),
  ),
};

export const clearSearchUsers = createAction('users/CLEAR_SEARCH_USERS');
export const clearError = createAction('users/CLEAR_ERROR');
