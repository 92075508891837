import React, { memo } from 'react';
import { Redirect, Route } from 'react-router-dom';

type Props = {
  isAuthenticated: boolean;
  path: string;
  children: React.ReactElement;
};

export const PrivateRoute: React.FC<Props> = memo(function PrivateRoute({
  children,
  isAuthenticated,
  ...restProps
}) {
  return (
    <Route
      {...restProps}
      render={({ location }) =>
        isAuthenticated ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: '/login',
              state: { from: location },
            }}
          />
        )
      }
    />
  );
});
