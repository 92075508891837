import React, { memo } from 'react';
import { Typography, TextField, Grid, Paper } from '@mui/material';

import { Course } from 'src/types';
import { useTranslation } from 'react-i18next';

type Props = {
  fieldsValue: Course;
  onChange: React.Dispatch<React.SetStateAction<Course>>;
  checkFieldsByValidImageUrl: () => void;
  clearError: () => void;
};

export const CourseCard: React.FC<Props> = memo(function CourseCard({
  fieldsValue,
  onChange,
  checkFieldsByValidImageUrl,
  clearError,
}) {
  const { t } = useTranslation();
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value, name } = event.target;

    switch (name) {
      case 'image-url':
        clearError();
        onChange((prevState) => ({
          ...prevState,
          imageUrl: value,
        }));
        checkFieldsByValidImageUrl();
        break;
      case 'course-url':
        clearError();
        onChange((prevState) => ({
          ...prevState,
          shopLink: value,
        }));
        checkFieldsByValidImageUrl();
        break;
      case 'course-id':
        onChange((prevState) => ({
          ...prevState,
          id: value,
        }));
        break;
    }
  };

  const { imageUrl, shopLink, id } = fieldsValue;

  return (
    <Grid item style={{ position: 'relative' }}>
      <Paper sx={{ p: 2, minHeight: 200, width: '100%', mt: 1, mb: 1 }}>
        <Typography sx={{ fontSize: '16px', fontWeight: 500, mb: 1 }} variant="h3" component="h3">
          {t('course.courseImageUrl')}
        </Typography>
        <TextField
          onChange={handleChange}
          value={imageUrl}
          name={'image-url'}
          fullWidth
          label={t('course.URL')}
          variant="standard"
        />
        <Typography
          sx={{ fontSize: '16px', fontWeight: 500, mb: 1, mt: 1 }}
          variant="h3"
          component="h3">
          {t('course.courseUrl')}
        </Typography>
        <TextField
          onChange={handleChange}
          value={shopLink}
          name={'course-url'}
          fullWidth
          label={t('course.URL')}
          variant="standard"
        />
        <Typography
          sx={{ fontSize: '16px', fontWeight: 500, mb: 1, mt: 1 }}
          variant="h3"
          component="h3">
          {t('course.courseId')}
        </Typography>
        <TextField
          onChange={handleChange}
          value={id}
          name={'course-id'}
          fullWidth
          label={t('course.ID')}
          variant="standard"
          inputProps={{ maxLength: 20 }}
        />
      </Paper>
    </Grid>
  );
});
