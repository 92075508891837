import React, { memo } from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Box from '@mui/material/Box';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import MDEditor from '@uiw/react-md-editor';
import ItemMask from 'src/components/ItemMask';
import { useTranslation } from 'react-i18next';
import { useFormatDate } from '../../../../hooks/useFormatDate';
import HistoryChange from '../../../../components/HistoryChange/HistoryChange';

const styles = {
  title: {
    fontSize: 16,
    fontWeight: 500,
    display: '-webkit-box',
    overflow: 'hidden',
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: 3,
  },
  body: {
    wordWrap: 'break-word',
    fontSize: 14,
    fontWeight: 400,
    lineClamp: 3,
    overflow: 'hidden',
    height: '45px',
    color: 'gray',
    display: '-webkit-box',
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: 3,
  },
  buttonContainer: {
    height: '50px',
    alignSelf: 'center',
  },
  button: {
    marginRight: '10px',
  },
};

type Props = {
  body: string;
  id: number;
  imageUrl?: string | null;
  title: string;
  onClick: (cardId: number) => void;
  onDelete: (newsId: number) => void;
  inMobileApp?: boolean;
  dateTime?: string;
  modifiedDate: string | null;
  modifiedByFirstName?: string | null;
  modifiedByLastName?: string | null;
};

export const IntroItem: React.FC<Props> = memo(function NewsItem({
  title,
  body,
  id,
  onClick,
  onDelete,
  inMobileApp,
  dateTime,
  modifiedDate,
  modifiedByFirstName,
  modifiedByLastName,
}) {
  const { t } = useTranslation();
  const { formattedDate: formattedDatePublish } = useFormatDate(dateTime);
  return (
    <Card sx={{ mb: 1 }} style={{ position: 'relative' }}>
      <CardContent
        sx={{
          flexDirection: 'row',
          display: 'flex',
          justifyContent: 'space-between',
          overflow: 'hiden',
          height: '120px',
        }}>
        <Box sx={{ width: '50%' }}>
          {inMobileApp && (
            <div style={{ position: 'relative', marginBottom: '16px' }}>
              <div
                style={{
                  position: 'absolute',
                  top: '-12px',
                  left: '-10px',
                  width: '170px',
                  display: 'flex',
                  alignItems: 'center',
                }}>
                <FiberManualRecordIcon sx={{ color: '#4caf50', width: 12, marginRight: '5px' }} />
                {/*<Typography*/}
                {/*  sx={{ color: '#4caf50', fontSize: '14px' }}*/}
                {/*  variant="subtitle1"*/}
                {/*  component="h4">*/}
                {/*  Block {id}*/}
                {/*</Typography>*/}
                <Typography
                  sx={{ color: '#4caf50', fontSize: '14px', ml: 1 }}
                  variant="subtitle1"
                  component="h4">
                  {t('highEnergySecrets.insideApp')}
                </Typography>
              </div>
            </div>
          )}
          <Typography variant="caption" sx={styles.title} component="h2" gutterBottom>
            {title}
          </Typography>
          <div data-color-mode="light">
            <MDEditor.Markdown source={body} />
          </div>
          {dateTime && (
            <Typography component="p" sx={{ fontSize: '14px' }}>
              {formattedDatePublish}
            </Typography>
          )}
        </Box>
        <Box sx={styles.buttonContainer}>
          <IconButton
            sx={styles.button}
            color="primary"
            onClick={() => {
              onClick(id);
            }}>
            <EditIcon />
          </IconButton>
          <IconButton
            color="primary"
            onClick={() => {
              onDelete(id);
            }}>
            <DeleteIcon />
          </IconButton>
        </Box>
      </CardContent>
      <HistoryChange
        modifiedByFirstName={modifiedByFirstName}
        modifiedByLastName={modifiedByLastName}
        modifiedDate={modifiedDate}
      />
      <ItemMask />
    </Card>
  );
});
