import React, { FC, ChangeEvent } from 'react';
import Box from '@mui/material/Box';
import PersonSearchIcon from '@mui/icons-material/PersonSearch';
import TextField from '@mui/material/TextField';
import { useTranslation } from 'react-i18next';

interface SearchProps {
  searchValue: string;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
}

const Search: FC<SearchProps> = ({ searchValue, onChange }) => {
  const { t } = useTranslation();
  return (
    <Box sx={{ marginBottom: 2, display: 'flex', justifyContent: 'flex-start' }}>
      <Box sx={{ display: 'flex', alignItems: 'flex-end', minWidth: 400 }}>
        <PersonSearchIcon sx={{ color: 'action.active', mr: 1, my: 0.5 }} />
        <TextField
          id="input-with-sx"
          label={t('users.search')}
          variant="standard"
          fullWidth
          value={searchValue}
          onChange={onChange}
        />
      </Box>
    </Box>
  );
};

export default Search;
