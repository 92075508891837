import { createUser, deleteUser, editUser, getUsers, getUser } from './actions';
import { API } from 'src/api';
import { put, takeLatest } from 'redux-saga/effects';
import { ActionType } from 'deox';
import { showStackBar } from 'src/modules/settings/actions';

function* getUsersSaga() {
  try {
    const { data } = yield API.getUsers();

    yield put(getUsers.success(data));
  } catch (e: any) {
    yield put(getUsers.fail({ error: e.message }));
  }
}

function* createUserSaga({ payload }: ActionType<typeof createUser.request>) {
  const { onSuccessCb, requestBody } = payload;
  try {
    yield API.createUser(requestBody);

    yield put(createUser.success());
    onSuccessCb && onSuccessCb();
    yield put(
      showStackBar({
        visible: true,
        message: 'User has been created.',
        variant: 'success',
      }),
    );
    yield put(getUsers.request());
  } catch (e: any) {
    if (e.response.data.errorKey) {
      yield put(createUser.fail({ error: e.response.data.errorKey }));
    } else {
      yield put(getUsers.fail({ error: e.message }));
    }
  }
}

function* deleteUserSaga({ payload }: ActionType<typeof deleteUser.request>) {
  try {
    yield API.deleteUser(payload);

    yield put(deleteUser.success());

    yield put(
      showStackBar({
        visible: true,
        message: 'User has been deleted.',
        variant: 'success',
      }),
    );
    yield put(getUsers.request());
  } catch (e: any) {
    yield put(deleteUser.fail({ error: e.message }));
  }
}

function* editUserSaga({ payload }: ActionType<typeof editUser.request>) {
  const { requestBody, onSuccessCb } = payload;

  try {
    const { data } = yield API.editUser(requestBody);

    yield put(editUser.success(data));

    onSuccessCb && onSuccessCb();

    yield put(
      showStackBar({
        visible: true,
        message: 'User has been changed.',
        variant: 'success',
      }),
    );

    yield put(getUsers.request());
  } catch (e: any) {
    yield put(editUser.fail({ error: e.message }));
  }
}

function* getUserSaga({ payload }: ActionType<typeof getUser.request>) {
  try {
    const { data } = yield API.getUser(payload);

    yield put(getUser.success(data));
  } catch (e: any) {
    yield put(getUser.fail({ error: e.message }));
  }
}

export function* watchUsers() {
  yield takeLatest(getUsers.request, getUsersSaga);
  yield takeLatest(createUser.request, createUserSaga);
  yield takeLatest(deleteUser.request, deleteUserSaga);
  yield takeLatest(editUser.request, editUserSaga);
  yield takeLatest(getUser.request, getUserSaga);
}
