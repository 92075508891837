/* eslint-disable @typescript-eslint/no-explicit-any */
import { API } from 'src/api';
import { ActionType } from 'deox';
import { takeLatest, put } from 'redux-saga/effects';

import { getCourses, editCourses } from './actions';

import { showStackBar } from 'src/modules/settings/actions';

function* getCoursesSaga({ payload }: ActionType<typeof getCourses.request>) {
  try {
    const { data } = yield API.getCourses(payload);

    yield put(getCourses.success(data));
  } catch (e) {
    yield put(getCourses.fail({ error: 'some error' }));
  }
}

function* editCoursesSaga({ payload }: ActionType<typeof editCourses.request>) {
  try {
    const { data } = yield API.editCourses(payload);

    yield put(editCourses.success(data));

    yield put(
      showStackBar({
        visible: true,
        message: 'Courses has been saved.',
        variant: 'success',
      }),
    );
  } catch (e: any) {
    console.log(e.response);
    yield put(editCourses.fail({ error: 'some error' }));
  }
}

export function* watchCourses() {
  yield takeLatest(editCourses.request, editCoursesSaga);
  yield takeLatest(getCourses.request, getCoursesSaga);
}
