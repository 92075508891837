import React, { FC, useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import { Store } from '../../types';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import LanguageDropdown from '../Login/LanguageDropdown/LanguageDropdown';
import Container from '@mui/material/Container';
import CssBaseline from '@mui/material/CssBaseline';
import { useDispatch, useSelector } from 'react-redux';
import Avatar from '@mui/material/Avatar';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import LoadingButton from '@mui/lab/LoadingButton';
import { useTranslation } from 'react-i18next';
import { cleanErrors, resetPassword } from '../../modules/auth/actions';
import { EmailConfirmationModal } from './components/ EmailConfirmationModal';

const theme = createTheme();

export const ResetPassword: FC = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [modalOpen, setModalOpen] = useState(false);

  const { loading, resetPasswordError } = useSelector((state: Store) => state.auth);

  const handleOpenModal = () => {
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const email: any = data.get('email');

    if (!email) {
      return;
    }

    dispatch(
      resetPassword.request({ requestBody: { username: email }, onSuccess: handleOpenModal }),
    );
  };

  const handleChange = () => {
    if (resetPasswordError) {
      dispatch(cleanErrors());
    }
  };

  useEffect(() => {
    dispatch(cleanErrors());
  }, [dispatch]);

  return (
    <ThemeProvider theme={theme}>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
          marginTop: '15px',
          marginRight: '15px',
        }}>
        <LanguageDropdown />
      </Box>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}>
          <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            {t('resetPassword.title')}
          </Typography>
          <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1, width: 400 }}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label={t('login.emailAddress')}
              name="email"
              autoComplete="email"
              autoFocus
              onChange={handleChange}
              error={Boolean(resetPasswordError)}
            />
            {resetPasswordError && (
              <Typography component="h5" variant="subtitle1" color="red">
                {t(`resetPassword.errors.${resetPasswordError}`)}
              </Typography>
            )}
            <LoadingButton
              loading={loading}
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}>
              {t('resetPassword.sendEmail')}
            </LoadingButton>
          </Box>
        </Box>
      </Container>

      <EmailConfirmationModal open={modalOpen} handleClose={handleCloseModal} />
    </ThemeProvider>
  );
};
