import React from 'react';
import { Provider, ProviderProps } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import store, { persistor } from './store';
import { AppRouter } from './routes/AppRouter';
import 'moment/locale/de';
import 'moment/locale/en-gb';

function App(): React.ReactElement {
  const providerProps: ProviderProps = {
    store: store,
  };

  return (
    <Provider {...providerProps}>
      <PersistGate persistor={persistor}>
        <AppRouter />
      </PersistGate>
    </Provider>
  );
}

export default App;
