import React, { memo, useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import { Loading } from 'src/components/Loading';
import { useTranslation } from 'react-i18next';

type Props = {
  videoUrl: string;
};

export const VideoIFrame: React.FC<Props> = memo(function VideoIFrame({ videoUrl }) {
  const { t } = useTranslation();
  const [videoId, setVideoId] = useState('');
  const [loading, setLoading] = useState(true);

  function youtubeParser(url: string) {
    const regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
    const match = url.match(regExp);
    return match && match[7].length == 11 ? match[7] : '';
  }

  useEffect(() => {
    setVideoId(youtubeParser(videoUrl));
  }, [videoUrl]);

  return (
    <Box alignItems="center" justifyContent="flex-start" sx={{ display: 'flex' }}>
      {loading && <Loading loading={loading} boxHeight="25vh" size={40} />}
      <Box sx={{ mt: 1, display: loading ? 'none' : 'block' }}>
        <iframe
          src={`https://www.youtube-nocookie.com/embed/${videoId}`}
          onLoad={() => {
            setLoading(false);
          }}
          title={t('youtube.video')}
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        />
      </Box>
    </Box>
  );
});
