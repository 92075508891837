import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Paper from '@mui/material/Paper';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import { ErrorMessage } from 'src/components/ErrorMessage';
import { Loading } from 'src/components/Loading';
import { checkImageUrl } from 'src/helpers/isValidImageUrl';
import { useSelector } from 'react-redux';
import { EditNewsRequestBody, Store } from 'src/types';
import MDEditor, { commands } from '@uiw/react-md-editor';
import { InputLabel } from '@mui/material';
import { useTranslation } from 'react-i18next';

type Props = {
  onSubmit: (payload: EditNewsRequestBody) => void;
  onCloseModal: () => void;
};

export const ModalInner: React.FC<Props> = memo(function ModalInner({ onSubmit, onCloseModal }) {
  const { t } = useTranslation();
  const { choosenNews, loadingOneNews } = useSelector((state: Store) => state.news);

  const [title, setTitle] = useState('');
  const [body, setBody] = useState('');
  const [imgUrl, setImgUrl] = useState('');

  const [isValidImageUrl, setSsValidImageUrl] = useState(false);

  const handleChangeTitle = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTitle(event.target.value);
  };

  const handleChangeBody = (value: string | undefined) => {
    setBody(String(value));
  };

  const handleChangeImgUrl = (event: React.ChangeEvent<HTMLInputElement>) => {
    setImgUrl(event.target.value);
  };

  const handleSubmit = useCallback(() => {
    onSubmit({
      body: body ? body : '',
      title: title ? title : '',
      imageUrl: imgUrl,
      section: 'NEWS',
    });
  }, [body, imgUrl, onSubmit, title]);

  useEffect(() => {
    if (choosenNews) {
      setTitle(choosenNews.title);
      setBody(choosenNews.body);
      setImgUrl(choosenNews.imageUrl);
    }
  }, [choosenNews]);

  useEffect(() => {
    setSsValidImageUrl(checkImageUrl(imgUrl));
  }, [imgUrl]);

  const allFieldsFulfilled = useMemo(() => {
    if (isValidImageUrl) {
      return true;
    }

    return false;
  }, [isValidImageUrl]);

  if (loadingOneNews) {
    return <Loading loading={loadingOneNews} />;
  }

  return (
    <Box
      component="form"
      sx={{
        '& .MuiTextField-root': { mb: 1, mt: 1 },
        display: 'flex',
        flexDirection: 'column',
      }}
      noValidate
      autoComplete="off">
      <TextField
        value={title}
        fullWidth
        id="standard-multiline-flexible"
        label={t('news.newsTitle')}
        onChange={handleChangeTitle}
        multiline
        rows={4}
        variant="standard"
        placeholder={t('news.enterNewsTitle')}
        inputProps={{ maxLength: 150 }}
      />
      <div data-color-mode="light">
        <InputLabel>{t('news.newsText')}</InputLabel>
        <MDEditor
          value={body}
          onChange={handleChangeBody}
          textareaProps={{
            placeholder: t('news.enterNewsText'),
            maxLength: 1000,
          }}
          commands={[
            commands.group([commands.title1, commands.title2, commands.title3], {
              name: 'title',
              groupName: 'title',
              buttonProps: { 'aria-label': 'Insert title' },
            }),
            commands.bold,
            commands.italic,
            commands.link,
            commands.hr,
            commands.quote,
            commands.unorderedListCommand,
            commands.orderedListCommand,
          ]}
          extraCommands={[commands.codeLive, commands.codePreview]}
        />
      </div>
      <TextField
        value={imgUrl ? imgUrl : ''}
        id="standard-multiline-static"
        label={t('news.imageUrl')}
        onChange={handleChangeImgUrl}
        rows={4}
        variant="standard"
      />

      {imgUrl && isValidImageUrl && (
        <Paper
          elevation={1}
          sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', mt: 1 }}>
          <img src={imgUrl} style={{ maxWidth: '100%' }} />
        </Paper>
      )}

      {imgUrl && !isValidImageUrl && (
        <ErrorMessage errorMessage={t('news.chosenImageUrlIsInvalid')} />
      )}

      <DialogActions>
        <Button onClick={onCloseModal}>{t('general.cancel')}</Button>
        <Button disabled={!allFieldsFulfilled} onClick={handleSubmit}>
          {t('general.save')}
        </Button>
      </DialogActions>
    </Box>
  );
});
