import React, { memo } from 'react';
import { Box, Typography } from '@mui/material';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

type Props = {
  errorMessage: string;
};

export const ErrorMessage: React.FC<Props> = memo(function ErrorMessage({ errorMessage }) {
  return (
    <Box sx={{ display: 'flex', mt: 1 }}>
      <ErrorOutlineIcon color="error" sx={{ mr: 1 }} />
      <Typography color="error">{errorMessage}</Typography>
    </Box>
  );
});
