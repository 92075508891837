import React, { memo } from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';

type Props = {
  loading: boolean;
  size?: number;
  boxHeight?: string;
};

export const Loading: React.FC<Props> = memo(function Loading({
  loading,
  size = 50,
  boxHeight = '60vh',
}) {
  return loading ? (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: boxHeight,
        width: '100%',
      }}>
      <CircularProgress size={size} />
    </Box>
  ) : null;
});
