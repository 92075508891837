import { createAction } from 'deox';

import {
  GetNewsResponse,
  GetNewsRequest,
  CreateNewsRequestBody,
  CreateNewsResponse,
  New,
  EditNewsRequestBody,
} from 'src/types';

export const getNews = {
  request: createAction(
    'news/GET_NEWS_REQUEST',
    (resolve) => (payload: GetNewsRequest) => resolve(payload),
  ),
  success: createAction(
    'news/GET_NEWS_SUCCESS',
    (resolve) => (payload: GetNewsResponse) => resolve(payload),
  ),
  fail: createAction(
    'news/GET_NEWS_FAIL',
    (resolve) => (payload: { error: string }) => resolve(payload),
  ),
};

export const createNews = {
  request: createAction(
    'news/CREATE_NEWS_REQUEST',
    (resolve) => (payload: { body: CreateNewsRequestBody; onSuccessCb?: () => void }) =>
      resolve(payload),
  ),
  success: createAction(
    'news/CREATET_NEWS_SUCCESS',
    (resolve) => (payload: CreateNewsResponse) => resolve(payload),
  ),
  fail: createAction(
    'news/CREATE_NEWS_FAIL',
    (resolve) => (payload: { error: string }) => resolve(payload),
  ),
};

export const chooseNews = createAction(
  'news/CHOOSE_NEWS',
  (resolve) => (payload: { newsId: number; onSuccessCb?: () => void }) => resolve(payload),
);

export const getNewsById = {
  request: createAction(
    'news/GET_NEWS_BY_ID_REQUEST',
    (resolve) => (payload: { newsId: number }) => resolve(payload),
  ),
  success: createAction(
    'news/GET_NEWS_BY_ID_SUCCESS',
    (resolve) => (payload: New) => resolve(payload),
  ),
  fail: createAction(
    'news/GET_NEWS_BY_ID_FAIL',
    (resolve) => (payload: { error: string }) => resolve(payload),
  ),
};

export const editNewsById = {
  request: createAction(
    'news/EDIT_NEWS_BY_ID_REQUEST',
    (resolve) => (payload: { body: EditNewsRequestBody; onSuccessCb?: () => void }) =>
      resolve(payload),
  ),
  success: createAction(
    'news/EDIT_NEWS_BY_ID_SUCCESS',
    (resolve) => (payload: New) => resolve(payload),
  ),
  fail: createAction(
    'news/EDIT_NEWS_BY_ID_FAIL',
    (resolve) => (payload: { error: string }) => resolve(payload),
  ),
};

export const deleteNewsById = {
  request: createAction(
    'news/DEELETE_NEWS_BY_ID_REQUEST',
    (resolve) => (payload: { newsId: number; onSuccessCb?: () => void; section: string }) =>
      resolve(payload),
  ),
  success: createAction('news/DEELETE_NEWS_BY_ID_SUCCESS'),
  fail: createAction(
    'news/DEELETE_NEWS_BY_ID_FAIL',
    (resolve) => (payload: { error: string }) => resolve(payload),
  ),
};

export const cleanChoosenNews = createAction('news/CLEAR_CHOOSEN_NEWS');
