import React, { memo, useCallback, useEffect } from 'react';
import { VariantType, useSnackbar } from 'notistack';
import { useSelector } from 'react-redux';
import { Store } from 'src/types';
import { useDispatch } from 'react-redux';

import { showStackBar } from 'src/modules/settings/actions';

export const Snackbar: React.FC = memo(function Snackbar() {
  const dispatch = useDispatch();

  const { enqueueSnackbar } = useSnackbar();

  const { stackBar } = useSelector((state: Store) => state.settings);

  const runSnackbar = useCallback(
    (variant: VariantType = 'default', message: string) => {
      // variant could be success, error, warning, info, or default
      enqueueSnackbar(message, { variant });
    },
    [enqueueSnackbar],
  );

  useEffect(() => {
    if (stackBar.visible) {
      runSnackbar(stackBar.variant, stackBar.message);

      setTimeout(() => {
        dispatch(
          showStackBar({
            message: '',
            visible: false,
            variant: 'default',
          }),
        );
      }, 3000);
    }
  }, [dispatch, runSnackbar, stackBar]);

  return null;
});
