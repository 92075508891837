import { createReducer } from 'deox';
import { produce } from 'immer';

import { CoursesState } from './types';

import { getCourses, editCourses } from './actions';

const initialState: CoursesState = {
  courses: [],
  loading: false,
  editLoading: false,
  modifiedDate: null,
  modifiedByFirstName: null,
  modifiedByLastName: null,
};

export const coursesReducer = createReducer(initialState, (handleAction) => [
  handleAction(getCourses.request, (state) =>
    produce(state, (draft) => {
      draft.loading = true;
    }),
  ),
  handleAction(getCourses.success, (state, { payload }) =>
    produce(state, (draft) => {
      draft.loading = false;
      draft.courses = payload.courses;
      draft.modifiedDate = payload.modifiedDate;
      draft.modifiedByFirstName = payload.modifiedByFirstName;
      draft.modifiedByLastName = payload.modifiedByLastName;
    }),
  ),
  handleAction(getCourses.fail, (state) =>
    produce(state, (draft) => {
      draft.loading = false;
    }),
  ),
  handleAction(editCourses.request, (state) =>
    produce(state, (draft) => {
      draft.editLoading = true;
    }),
  ),
  handleAction(editCourses.success, (state, { payload }) =>
    produce(state, (draft) => {
      draft.editLoading = false;
      draft.courses = payload.courses;
      draft.modifiedDate = payload.modifiedDate;
      draft.modifiedByFirstName = payload.modifiedByFirstName;
      draft.modifiedByLastName = payload.modifiedByLastName;
    }),
  ),
  handleAction(editCourses.fail, (state) =>
    produce(state, (draft) => {
      draft.editLoading = false;
    }),
  ),
]);
