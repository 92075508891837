import { createAction } from 'deox';
import {
  GetCoursesRequestParams,
  GetCoursesResponse,
  EditCoursesRequestBody,
  EditCoursesResponse,
} from 'src/types';

export const getCourses = {
  request: createAction(
    'courses/GET_COURSES_REQUEST',
    (resolve) => (payload: GetCoursesRequestParams) => resolve(payload),
  ),
  success: createAction(
    'courses/GET_COURSES_SUCCESS',
    (resolve) => (payload: GetCoursesResponse) => resolve(payload),
  ),
  fail: createAction(
    'courses/GET_COURSES_FAIL',
    (resolve) => (payload: { error: string }) => resolve(payload),
  ),
};

export const editCourses = {
  request: createAction(
    'courses/SAVE_COURSES_REQUEST',
    (resolve) => (payload: EditCoursesRequestBody) => resolve(payload),
  ),
  success: createAction(
    'courses/SAVE_COURSES_SUCCESS',
    (resolve) => (payload: EditCoursesResponse) => resolve(payload),
  ),
  fail: createAction(
    'courses/SAVE_COURSES_FAIL',
    (resolve) => (payload: { error: string }) => resolve(payload),
  ),
};
