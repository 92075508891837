import { createAction } from 'deox';

import { LoginRequestBody, LoginResponse, ResetPasswordRequestBody } from 'src/types';

export const login = {
  request: createAction(
    'auth/LOGIN_REQUEST',
    (resolve) => (payload: LoginRequestBody) => resolve(payload),
  ),
  success: createAction(
    'auth/LOGIN_SUCCESS',
    (resolve) => (payload: LoginResponse) => resolve(payload),
  ),
  fail: createAction(
    'auth/LOGIN_FAIL',
    (resolve) => (payload: { error: string }) => resolve(payload),
  ),
};

export const resetPassword = {
  request: createAction(
    'auth/RESET_PASSWORD_REQUEST',
    (resolve) =>
      (payload: {
        requestBody: ResetPasswordRequestBody;
        onSuccess?: () => void;
        onFail?: () => void;
      }) =>
        resolve(payload),
  ),
  success: createAction('auth/RESET_PASSWORD_SUCCESS', (resolve) => () => resolve()),
  fail: createAction(
    'auth/RESET_PASSWORD_FAIL',
    (resolve) => (payload: { error: string }) => resolve(payload),
  ),
};

export const cleanErrors = createAction('auth/CLEAN_ERRORS', (resolve) => () => resolve());

export const getRoles = {
  request: createAction('auth/GET_ROLES_REQUEST', (resolve) => () => resolve()),
  success: createAction(
    'auth/GET_ROLES_SUCCESS',
    (resolve) => (payload: string[]) => resolve(payload),
  ),
  fail: createAction(
    'auth/GET_ROLES_FAIL',
    (resolve) => (payload: { error: string }) => resolve(payload),
  ),
};
