import moment from 'moment';
import { useSelector } from 'react-redux';
import { Store } from '../types';

interface UseFormatDateResult {
  formattedDate: string | null;
}

export const useFormatDate = (date?: string | null): UseFormatDateResult => {
  const { appLanguage } = useSelector((state: Store) => state.settings);
  moment.locale(appLanguage.toLowerCase());
  const formattedDate = moment(date).format('DD MMM YYYY HH:mm');
  return { formattedDate: date ? formattedDate : null };
};
