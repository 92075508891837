import React, { memo } from 'react';
import MobileDateTimePicker from '@mui/lab/MobileDateTimePicker';
import TextField from '@mui/material/TextField';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import AdapterMoment from '@mui/lab/AdapterMoment';

import { Stack } from '@mui/material';

import moment, { Moment } from 'moment';
import { useTranslation } from 'react-i18next';

type Props = {
  publishTime: Moment | null;
  setPublishTime: (date: Moment | null) => void;
  disabled?: boolean;
};

export const DateTimePicker: React.FC<Props> = memo(function DateTimePicker({
  publishTime,
  setPublishTime,
  disabled,
}) {
  const { t } = useTranslation();
  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <Stack flexDirection={'row'} sx={{ mt: 1 }}>
        <MobileDateTimePicker
          renderInput={(props) => <TextField {...props} />}
          label={t('notifications.sendTime')}
          minDate={moment()}
          ampm={false}
          value={publishTime}
          disabled={disabled}
          onChange={(newValue) => {
            setPublishTime(newValue);
          }}
        />
      </Stack>
    </LocalizationProvider>
  );
});
