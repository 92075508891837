import { VariantType } from 'notistack';
import { createAction } from 'deox';
import { LANGUAGES } from '../../constants/constants';

export const changeLanguage = createAction(
  'settings/CHANGE_LANGUAGE',
  (resolve) => (payload: { language: LANGUAGES.EN | LANGUAGES.DE }) => resolve(payload),
);

export const showStackBar = createAction(
  'settings/SHOW_STACK_BAR',
  (resolve) => (payload: { visible: boolean; message: string; variant?: VariantType }) =>
    resolve(payload),
);
