import React from 'react';
import Avatar from '@mui/material/Avatar';
import LoadingButton from '@mui/lab/LoadingButton';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useSelector } from 'react-redux';
import { Store } from 'src/types';

import { login } from 'src/modules/auth/actions';

import { useDispatch } from 'react-redux';
import LanguageDropdown from './LanguageDropdown/LanguageDropdown';
import { useTranslation } from 'react-i18next';
import { Button } from '@mui/material';
import { useHistory } from 'react-router-dom';

const theme = createTheme();

export const Login: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const history = useHistory();

  const { loading, error } = useSelector((state: Store) => state.auth);

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const email: any = data.get('email');
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const password: any = data.get('password');

    if (!email || !password) {
      return;
    }

    dispatch(
      login.request({
        username: email.split(' ').join(''),
        password: password.split(' ').join(''),
        language: 'DE',
      }),
    );
  };

  const onForgotPasswordClick = () => {
    history.push('/reset-password');
  };

  return (
    <ThemeProvider theme={theme}>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
          marginTop: '15px',
          marginRight: '15px',
        }}>
        <LanguageDropdown />
      </Box>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}>
          <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            {t('login.signIn')}
          </Typography>
          <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label={t('login.emailAddress')}
              name="email"
              autoComplete="email"
              autoFocus
              error={Boolean(error)}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label={t('login.password')}
              type="password"
              id="password"
              autoComplete="current-password"
              error={Boolean(error)}
            />
            {error && (
              <Typography component="h5" variant="subtitle1" color="red">
                {t('login.incorrectPasswordOrEmail')}
              </Typography>
            )}
            <LoadingButton
              loading={loading}
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}>
              {t('login.signIn')}
            </LoadingButton>
          </Box>

          <Button onClick={onForgotPasswordClick} style={{ marginLeft: 'auto' }} variant="text">
            {t('resetPassword.loginButton')}
          </Button>
        </Box>
      </Container>
    </ThemeProvider>
  );
};
