import { axiosInstance } from './axiosInstance';

import {
  GetNewsRequest,
  CreateNewsRequestBody,
  LoginRequestBody,
  EditNewsRequestBody,
  GetYoutubeVideosRequest,
  EditYoutubeVideosRequestBody,
  GetCoursesRequestParams,
  EditCoursesRequestBody,
  CreateNotificationRequestBody,
  GetNotificationsRequestParams,
  EditNotificationRequestBody,
  DeleteNotificationRequestParams,
  CreateUserRequest,
  DeleteUserRequestParams,
  GetUserRequestParams,
  ResetPasswordRequestBody,
} from 'src/types';

export const API = {
  getNews: (params: GetNewsRequest) => {
    return axiosInstance.instance!.get('home-screen/news', {
      params,
    });
  },
  createNews: (body: CreateNewsRequestBody) => {
    return axiosInstance.instance!.post('home-screen/news', body);
  },
  login: (body: LoginRequestBody) => {
    return axiosInstance.instance!.post('dashboard/authenticate', body);
  },
  getNewsById: (params: { newsId: number }) => {
    return axiosInstance.instance!.get(`home-screen/news/${params.newsId}`);
  },
  editNewsById: (params: { body: EditNewsRequestBody; newsId: number }) => {
    return axiosInstance.instance!.put(`home-screen/news/${params.newsId}`, params.body);
  },
  deleteNewsById: (params: { newsId: number }) => {
    return axiosInstance.instance!.delete(`home-screen/news/${params.newsId}`);
  },
  getYoutubeVideos: (params: GetYoutubeVideosRequest) => {
    return axiosInstance.instance!.get('home-screen/youtube', { params });
  },
  editYoutubeVideos: (body: EditYoutubeVideosRequestBody) => {
    return axiosInstance.instance!.post('home-screen/youtube', body);
  },
  getCourses: (params: GetCoursesRequestParams) => {
    return axiosInstance.instance!.get('home-screen/courses', { params });
  },
  editCourses: (body: EditCoursesRequestBody) => {
    return axiosInstance.instance!.post('home-screen/courses', body);
  },
  createNotification: (body: CreateNotificationRequestBody) => {
    return axiosInstance.instance!.post('home-screen/notifications', body);
  },
  getNotifications: (params: GetNotificationsRequestParams) => {
    return axiosInstance.instance!.get('home-screen/notifications', { params });
  },
  editNotification: (body: EditNotificationRequestBody, notifyId: number) => {
    return axiosInstance.instance!.put(`home-screen/notifications/${notifyId}`, body);
  },
  deleteNotification: (params: DeleteNotificationRequestParams) => {
    return axiosInstance.instance!.delete(`home-screen/notifications/${params.notificationId}`);
  },
  getUsers: () => {
    return axiosInstance.instance!.get('admin/users');
  },
  createUser: (requestBody: CreateUserRequest) => {
    return axiosInstance.instance!.post('admin/users', requestBody);
  },
  deleteUser: (params: DeleteUserRequestParams) => {
    return axiosInstance.instance!.delete(`admin/users/${params.userId}`);
  },
  editUser: (body: CreateUserRequest) => {
    return axiosInstance.instance!.put('admin/users', body);
  },
  getUser: (params: GetUserRequestParams) => {
    return axiosInstance.instance!.get('admin/users/search', { params });
  },
  resetPassword: (body: ResetPasswordRequestBody) => {
    return axiosInstance.instance!.post('dashboard/reset', body);
  },
  getRoles: () => {
    return axiosInstance.instance!.get('dashboard/authenticate');
  },
};
