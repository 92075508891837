import { createReducer } from 'deox';
import { produce } from 'immer';

import { NewsState } from './types';

import {
  getNews,
  createNews,
  getNewsById,
  editNewsById,
  cleanChoosenNews,
  deleteNewsById,
} from './actions';

const initialState: NewsState = {
  news: [],
  loading: false,
  error: '',
  choosenNewsId: null,
  choosenNews: null,
  loadingOneNews: false,
  currentPage: 0,
  totalPages: 0,
};

export const newsReducer = createReducer(initialState, (handleAction) => [
  handleAction(getNews.request, (state) =>
    produce(state, (draft) => {
      draft.loading = true;
    }),
  ),
  handleAction(getNews.success, (state, { payload }) =>
    produce(state, (draft) => {
      draft.loading = false;
      draft.news = payload.content;
      draft.totalPages = payload.totalPages;
    }),
  ),
  handleAction(getNews.fail, (state, { payload }) =>
    produce(state, (draft) => {
      draft.loading = false;
      draft.error = payload.error;
    }),
  ),
  handleAction(createNews.request, (state) =>
    produce(state, (draft) => {
      draft.loading = true;
    }),
  ),
  handleAction(createNews.success, (state) =>
    produce(state, (draft) => {
      draft.loading = false;
    }),
  ),
  handleAction(createNews.fail, (state, { payload }) =>
    produce(state, (draft) => {
      draft.loading = false;
      draft.error = payload.error;
    }),
  ),
  handleAction(getNewsById.request, (state, { payload }) =>
    produce(state, (draft) => {
      draft.loadingOneNews = true;
      draft.choosenNewsId = payload.newsId;
    }),
  ),
  handleAction(getNewsById.success, (state, { payload }) =>
    produce(state, (draft) => {
      draft.loadingOneNews = false;
      draft.choosenNews = payload;
    }),
  ),
  handleAction(getNewsById.fail, (state, { payload }) =>
    produce(state, (draft) => {
      draft.loadingOneNews = false;
      draft.error = payload.error;
    }),
  ),
  handleAction(editNewsById.request, (state) =>
    produce(state, (draft) => {
      draft.loadingOneNews = true;
    }),
  ),
  handleAction(editNewsById.success, (state) =>
    produce(state, (draft) => {
      draft.loadingOneNews = false;
    }),
  ),
  handleAction(editNewsById.fail, (state, { payload }) =>
    produce(state, (draft) => {
      draft.loadingOneNews = false;
      draft.error = payload.error;
    }),
  ),
  handleAction(deleteNewsById.request, (state) =>
    produce(state, (draft) => {
      draft.loading = true;
    }),
  ),
  handleAction(deleteNewsById.success, (state) =>
    produce(state, (draft) => {
      draft.loading = false;
    }),
  ),
  handleAction(deleteNewsById.fail, (state, { payload }) =>
    produce(state, (draft) => {
      draft.loading = false;
      draft.error = payload.error;
    }),
  ),
  handleAction(cleanChoosenNews, (state) =>
    produce(state, (draft) => {
      draft.choosenNews = null;
      draft.choosenNewsId = null;
    }),
  ),
]);
