const servers = {
  dev: {
    name: 'Back-dev',
    url: 'saint-clairs.impltech.com',
  },
  stage: {
    name: 'Back-stage',
    url: '',
  },
  prod: {
    name: 'Production',
    url: '',
  },
};

export const server = servers.dev;

export const BASE_BACKEND_URL = server.url;

let api = '';

if (process.env.NODE_ENV === 'development') {
  api = `https://${BASE_BACKEND_URL}/api/`;
} else {
  api = `${process.env.REACT_APP_BACKEND_HOST}/` || BASE_BACKEND_URL;

  if (process.env.REACT_APP_ENV === 'prod') {
    api = `${api}api/`;
  }
}

export const API_URL = api;
