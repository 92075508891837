import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import MDEditor, { commands } from '@uiw/react-md-editor';
import TextField from '@mui/material/TextField';
import Paper from '@mui/material/Paper';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';

import { ErrorMessage } from 'src/components/ErrorMessage';
import { Loading } from 'src/components/Loading';

import { DateTimePicker } from 'src/pages/Notifications/components/DateTimePicker';

import { checkImageUrl } from 'src/helpers/isValidImageUrl';

import { Store } from 'src/types';
import moment, { Moment } from 'moment';
import { InputLabel } from '@mui/material';
import { useTranslation } from 'react-i18next';

type Props = {
  onSubmit: (payload: {
    content: string;
    shortContent: string;
    imageUrl: string;
    publishTime: string;
    title: string;
  }) => void;
  onCloseModal: () => void;
};

export const NotifyInnerModal: React.FC<Props> = ({ onCloseModal, onSubmit }) => {
  const { t } = useTranslation();
  const [content, setContent] = useState('');
  const [shortContent, setShortContent] = useState('');
  const [imageUrl, setImageUrl] = useState('');
  const [publishTime, setPublishTime] = useState<Moment | null>(moment());
  const [title, setTitle] = useState('');
  const [error, setError] = useState({
    first: '',
    second: '',
  });

  const { loading, chosenNotify } = useSelector((state: Store) => state.notify);

  const [isValidImageUrl, setSsValidImageUrl] = useState(false);

  const validateFields = () => {
    const isImageValid = checkImageUrl(imageUrl);
    const isPublishTimeValid = publishTime && publishTime > moment();

    if (!shortContent) {
      setError((prev) => ({
        ...prev,
        first: t('notifications.shotContentIsRequired'),
      }));
      return false;
    }

    if (!content) {
      setError((prev) => ({
        ...prev,
        first: t('notifications.contentIsRequired'),
      }));
      return false;
    }

    if (!isImageValid && imageUrl !== '') {
      setError((prev) => ({
        ...prev,
        first: t('notifications.chosenFormatImageDoesNotSupport'),
      }));
    }

    if (!isPublishTimeValid && !chosenNotify?.isSent) {
      setError((prev) => ({
        ...prev,
        second: t('notifications.timeMustBeMoreThanCurrent'),
      }));
    }

    if ((imageUrl !== '' && !isImageValid) || (!isPublishTimeValid && !chosenNotify?.isSent)) {
      return false;
    }

    return true;
  };

  const handleChangeTitle = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTitle(event.target.value);
  };

  const handleChangeContent = (value: string | undefined) => {
    setContent(String(value));
  };

  const handleChangeShortContent = (event: React.ChangeEvent<HTMLInputElement>) => {
    setShortContent(event.target.value);
  };

  const handleChangeImgUrl = (event: React.ChangeEvent<HTMLInputElement>) => {
    setImageUrl(event.target.value);
  };

  const handleSubmit = () => {
    const isFieldsValid = validateFields();

    if (isFieldsValid) {
      onSubmit({
        content,
        shortContent,
        imageUrl,
        publishTime: publishTime ? publishTime.toISOString() : moment().toISOString(),
        title,
      });
    }
  };

  useEffect(() => {
    if (chosenNotify) {
      setContent(chosenNotify.content);
      setShortContent(chosenNotify.shortContent);
      setTitle(chosenNotify.title);
      setImageUrl(chosenNotify.imageUrl);
      setPublishTime(moment(chosenNotify.publishTime));
    }
  }, [chosenNotify, imageUrl]);

  useEffect(() => {
    setSsValidImageUrl(checkImageUrl(imageUrl));
  }, [imageUrl]);

  useEffect(() => {
    setError({
      first: '',
      second: '',
    });
  }, [imageUrl, publishTime]);

  const allFieldsFulfilled = useMemo(() => {
    if (content && title) {
      return true;
    }

    return false;
  }, [content, title]);

  if (loading) {
    return <Loading loading={loading} />;
  }

  return (
    <Box
      component="form"
      sx={{
        '& .MuiTextField-root': { mb: 1, mt: 1 },
        display: 'flex',
        flexDirection: 'column',
      }}
      noValidate
      autoComplete="off">
      <TextField
        value={title}
        fullWidth
        id="standard-multiline-flexible"
        label={t('notifications.title')}
        onChange={handleChangeTitle}
        multiline
        rows={2}
        variant="standard"
        placeholder={t('notifications.enterNotificationTitle')}
        inputProps={{ maxLength: 150 }}
      />
      <TextField
        value={shortContent}
        id="standard-textarea"
        label={t('notifications.shortContent')}
        placeholder={t('notifications.enterNotificationShortContent')}
        onChange={handleChangeShortContent}
        multiline
        rows={4}
        variant="standard"
        inputProps={{ maxLength: 1000 }}
      />
      <div data-color-mode="light">
        <InputLabel>{t('notifications.notificationContent')}</InputLabel>
        <MDEditor
          value={content}
          onChange={handleChangeContent}
          textareaProps={{
            placeholder: t('notifications.enterNotificationContent'),
            maxLength: 1000,
          }}
          commands={[
            commands.group([commands.title1, commands.title2, commands.title3], {
              name: 'title',
              groupName: 'title',
              buttonProps: { 'aria-label': 'Insert title' },
            }),
            commands.bold,
            commands.italic,
            commands.link,
            commands.hr,
            commands.quote,
            commands.unorderedListCommand,
            commands.orderedListCommand,
          ]}
          extraCommands={[commands.codeLive, commands.codePreview]}
        />
      </div>
      <TextField
        value={imageUrl ? imageUrl : ''}
        id="standard-multiline-static"
        label={t('notifications.imageUrl')}
        onChange={handleChangeImgUrl}
        rows={4}
        variant="standard"
      />

      {imageUrl && isValidImageUrl && (
        <Paper
          elevation={1}
          sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', mt: 1 }}>
          <img src={imageUrl} style={{ maxWidth: '100%' }} />
        </Paper>
      )}

      <DateTimePicker
        setPublishTime={setPublishTime}
        publishTime={publishTime}
        disabled={chosenNotify?.isSent}
      />

      {error.first && <ErrorMessage errorMessage={error.first} />}
      {error.second && <ErrorMessage errorMessage={error.second} />}

      <DialogActions>
        <Button onClick={onCloseModal}>{t('general.cancel')}</Button>
        <Button disabled={!allFieldsFulfilled} onClick={handleSubmit}>
          {t('general.save')}
        </Button>
      </DialogActions>
    </Box>
  );
};
