import { API } from 'src/api';
import { ActionType } from 'deox';
import { takeLatest, put } from 'redux-saga/effects';

import { getRoles, login, resetPassword } from './actions';

function* loginSaga({ payload }: ActionType<typeof login.request>) {
  try {
    const { data } = yield API.login(payload);

    yield put(login.success(data));
  } catch (e) {
    yield put(login.fail({ error: 'some error' }));
  }
}

function* resetPasswordSaga({ payload }: ActionType<typeof resetPassword.request>) {
  const { onSuccess, onFail, requestBody } = payload;

  try {
    yield API.resetPassword(requestBody);

    yield put(resetPassword.success());

    onSuccess && onSuccess();
  } catch (e: any) {
    const errorMessage = e?.response?.data?.status || 'internalError';

    yield put(resetPassword.fail({ error: errorMessage }));

    onFail && onFail();
  }
}

function* getRolesSaga() {
  try {
    const { data } = yield API.getRoles();

    yield put(getRoles.success(data));
  } catch (e) {
    yield put(getRoles.fail({ error: 'some error' }));
  }
}

export function* watchAuth() {
  yield takeLatest(login.request, loginSaga);
  yield takeLatest(resetPassword.request, resetPasswordSaga);
  yield takeLatest(getRoles.request, getRolesSaga);
}
