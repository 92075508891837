import { createReducer } from 'deox';
import { produce } from 'immer';

import { YoutubeState } from './types';

import { getYoutubeVideos, editYoutubeVideos } from './actions';

const initialState: YoutubeState = {
  youtubeVideos: [],
  loading: false,
  editLoading: false,
  error: '',
  modifiedDate: null,
  modifiedByFirstName: null,
  modifiedByLastName: null,
};

export const youtubeReducer = createReducer(initialState, (handleAction) => [
  handleAction(getYoutubeVideos.request, (state) =>
    produce(state, (draft) => {
      draft.loading = true;
    }),
  ),
  handleAction(getYoutubeVideos.success, (state, { payload }) =>
    produce(state, (draft) => {
      draft.loading = false;
      draft.youtubeVideos = payload.links;
      draft.modifiedDate = payload.modifiedDate;
      draft.modifiedByFirstName = payload.modifiedByFirstName;
      draft.modifiedByLastName = payload.modifiedByLastName;
    }),
  ),
  handleAction(getYoutubeVideos.fail, (state) =>
    produce(state, (draft) => {
      draft.loading = false;
    }),
  ),
  handleAction(editYoutubeVideos.request, (state) =>
    produce(state, (draft) => {
      draft.editLoading = true;
    }),
  ),
  handleAction(editYoutubeVideos.success, (state, { payload }) =>
    produce(state, (draft) => {
      draft.editLoading = false;
      draft.youtubeVideos = payload.links;
      draft.modifiedDate = payload.modifiedDate;
      draft.modifiedByFirstName = payload.modifiedByFirstName;
      draft.modifiedByLastName = payload.modifiedByLastName;
    }),
  ),
  handleAction(editYoutubeVideos.fail, (state) =>
    produce(state, (draft) => {
      draft.editLoading = false;
    }),
  ),
]);
