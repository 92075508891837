import React, { useEffect, useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  getNews,
  getNewsById,
  editNewsById,
  deleteNewsById,
  createNews,
  cleanChoosenNews,
} from 'src/modules/news/actions';

import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';

import { Loading } from 'src/components/Loading';
import { NewsItem } from 'src/pages/News/components/NewsItem';
import { Modal } from 'src/components/Modal';
import { AddButton } from 'src/components/AddButton';

import { ModalInner } from './components/ModalInner';

import { Store, EditNewsRequestBody } from 'src/types';
import { Typography, Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';

export const News: React.FC = () => {
  const { t } = useTranslation();
  const [modalOpen, setModalOpen] = useState(false);
  const [page, setPage] = useState(0);
  const [createNewsModalVisible, setCreateNewsModalVisible] = useState(false);

  const { loading, news, totalPages } = useSelector((state: Store) => state.news);
  const { appLanguage } = useSelector((state: Store) => state.settings);

  const dispatch = useDispatch();

  const openCreateNewsModal = useCallback(() => {
    setCreateNewsModalVisible(true);
  }, []);

  const hideCreateNewsModal = useCallback(() => {
    setCreateNewsModalVisible(false);
  }, []);

  const handleSelectNewsId = useCallback(
    (newsId: number) => {
      dispatch(getNewsById.request({ newsId: newsId }));
      setModalOpen(true);
    },
    [dispatch],
  );

  const onDeleteNews = useCallback(
    (newsId: number) => {
      dispatch(deleteNewsById.request({ newsId, section: 'NEWS' }));
    },
    [dispatch],
  );

  const onCloseModal = useCallback(() => {
    setModalOpen(false);

    dispatch(cleanChoosenNews());
  }, [dispatch]);

  const onEditChosenNews = useCallback(
    (payload: EditNewsRequestBody) => {
      dispatch(
        editNewsById.request({
          body: payload,
          onSuccessCb: () => {
            onCloseModal();
          },
        }),
      );
    },
    [dispatch, onCloseModal],
  );

  const onCreateNewNews = useCallback(
    (payload: { body: string; imageUrl: string; title: string }) => {
      const { body, imageUrl, title } = payload;

      dispatch(
        createNews.request({
          body: {
            body,
            imageUrl,
            title,
            language: appLanguage,
            section: 'NEWS',
          },
          onSuccessCb: () => {
            hideCreateNewsModal();
          },
        }),
      );
    },
    [appLanguage, dispatch, hideCreateNewsModal],
  );

  const handleChangePage = (event: React.ChangeEvent<unknown>, value: number) => {
    // need subtract 1, because pages received from back is starting from 0
    setPage(value - 1);
  };

  useEffect(() => {
    dispatch(
      getNews.request({
        language: appLanguage,
        page: page,
        size: 8,
        sort: 'modifiedAt,desc',
        section: 'NEWS',
      }),
    );
  }, [dispatch, appLanguage, page]);

  if (loading) {
    return <Loading loading={loading} />;
  }

  return (
    <Grid container spacing={2} flexDirection={'column'}>
      <Grid item xs={12}>
        {news.length > 0 ? (
          news.map((item, index) => (
            <NewsItem
              inMobileApp={index === 0 || index === 1 || index === 2 || index === 3}
              key={item.id}
              body={item.body}
              imageUrl={item.imageUrl}
              title={item.title}
              id={item.id}
              onClick={handleSelectNewsId}
              onDelete={onDeleteNews}
              modifiedDate={item.modifiedDate}
              modifiedByFirstName={item.modifiedByFirstName}
              modifiedByLastName={item.modifiedByLastName}
            />
          ))
        ) : (
          <Typography sx={{ fontSize: '14px' }} component="h3" variant="h5" color="inherit">
            {t('news.seemsLike')}
          </Typography>
        )}
        {news.length > 0 && (
          <Stack direction="row" justifyContent="center" spacing={2}>
            <Pagination onChange={handleChangePage} count={totalPages} page={page + 1} />
          </Stack>
        )}

        <Modal title={t('news.editNews')} isOpen={modalOpen} handleClose={onCloseModal}>
          <ModalInner onCloseModal={onCloseModal} onSubmit={onEditChosenNews} />
        </Modal>
        <Modal
          title={t('news.createNews')}
          isOpen={createNewsModalVisible}
          handleClose={hideCreateNewsModal}>
          <ModalInner onCloseModal={hideCreateNewsModal} onSubmit={onCreateNewNews} />
        </Modal>
        <AddButton onClick={openCreateNewsModal} />
      </Grid>
    </Grid>
  );
};
